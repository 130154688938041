import React, { FC } from "react";
import styled from "styled-components";
import modalBg from "../assets/modal_bg.jpg";

interface ModalProps {
  show: boolean;
  children: React.ReactNode;
}

const ModalOverlay = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ show }) => (show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-image: url(${modalBg});
  background-color: #fbfbfc;
  background-repeat: no-repeat;
  background-position: center top;
  background-attachment: fixed;
  background-size: 1440px;
  z-index: 9999;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: 10px;
    overflow-y: auto;
  }
`;

const ModalContent = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 24px 16px;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: 36px 40px;
    border-radius: 4px;
    max-width: 752px;
    box-sizing: border-box;
    height: auto;
    box-shadow: 0px 10px 20px 0px #00000014, 0px 3px 6px 0px #0000000d,
      0px 0px 1px 0px #0000001a;
  }
`;

const Modal: FC<ModalProps> = ({ show, children }) => {
  return (
    <ModalOverlay show={show}>
      <ModalContent>{children}</ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
