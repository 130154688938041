import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../components/Modal";
import { ButtonGatsbyLink, ButtonLink } from "site-ui/src/components/Button";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Heading = styled.h2`
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 0;
`;

const Text = styled.p`
  list-style: none;
  margin: 48px 0 24px;
  font-size: 16px;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
`;

const ListItem = styled.li`
  font-size: 16px;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
  display: flex;
  align-items: center;
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289C0.683417 3.90237 1.31658 3.90237 1.70711 4.29289L5 7.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z' fill='url(%23paint0_linear_1747_1318)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1747_1318' x1='0' y1='4.73684' x2='14' y2='4.73684' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23142146'/%3E%3Cstop offset='0.500462' stop-color='%23266593'/%3E%3Cstop offset='1' stop-color='%2350AFC8'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

const TextContainer = styled.div`
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  margin-top: 2rem;
  gap: 16px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

const ModalContent = () => {
  return (
    <ContentWrapper>
      <TextContainer>
        <Heading>Want to track and manage all your shipments?</Heading>
        <Text>
          <strong>
            Get full access to your personal shipment dashboard for free!
          </strong>
        </Text>
        <List>
          <ListItem>Track live location of vessel</ListItem>
          <ListItem>Stay up to date with latest changes</ListItem>
          <ListItem>Complete shipment tasks</ListItem>
          <ListItem>Book your next shipment easily</ListItem>
        </List>
      </TextContainer>
      <ButtonContainer>
        <ButtonGatsbyLink variant="secondary" to="/how-twill-works">
          More info
        </ButtonGatsbyLink>
        <ButtonLink href="/my/sign-up">Sign up</ButtonLink>
      </ButtonContainer>
      <Footer>
        <p>
          Already have an account? <a href="/my/login">Log in</a>
        </p>
      </Footer>
    </ContentWrapper>
  );
};

function HomePage() {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <ContentWrapper>
      <Heading>Want to track and manage all your shipments?</Heading>
      <Modal show onClose={handleCloseModal}>
        <ModalContent />
      </Modal>
    </ContentWrapper>
  );
}

export default HomePage;
